import { baseAPI } from "./config";

function authMiddleware(Vue, Axios, router) {
  Axios.defaults.baseURL = baseAPI;

  Axios.interceptors.request.use(
    function (config) {
      if (!config.headers.common["Authorization"]) {
        config.headers.common["Authorization"] = localStorage.getItem("token");
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        router.push("/");
      } else {
        return Promise.reject(error);
      }
    },
  );
}

export default authMiddleware;
