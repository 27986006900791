import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

if (!localStorage.getItem("language")) {
  const lang = navigator.language || navigator.userLanguage;
  localStorage.setItem("language", langAlias(lang));
} else {
  const lang = localStorage.getItem("language");
  localStorage.setItem("language", langAlias(lang));
}

function langAlias(lang) {
  if (lang.indexOf("ru") || lang.indexOf("ua") || lang.indexOf("cz")) {
    return "ru";
  } else {
    return "ru"; // set en to disable ru default
  }
}

export default new Vuex.Store({
  // Состояние
  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    currentLang: localStorage.getItem("language"),
    surgeryPage: 1,
    surgeryItems: [],
    surgeryFavorites: [],
    orthopedicsPage: 1,
    orthopedicsItems: [],
    orthopedicsFavorites: [],
    orthodonticsPage: 1,
    orthodonticsItems: [],
    orthodonticsFavorites: [],
    terapyPage: 1,
    terapyItems: [],
    terapyFavorites: [],
    photoBankPage: 1,
    photoBankType: "0",
    photoBankList: [],
  },

  // Изменение состояний
  mutations: {
    signin(state) {
      state.isLoggedIn = true;
    },
    signout(state) {
      state.isLoggedIn = false;
    },
    changeLanguage(state) {
      state.currentLang = localStorage.getItem("language");
    },
    setSurgeryItems(state, { page, list, favorites }) {
      state.surgeryPage = page;
      state.surgeryItems = state.surgeryItems.concat(list);
      state.surgeryFavorites = favorites;
    },
    resetSurgeryItems(state) {
      state.surgeryPage = 1;
      state.surgeryItems = [];
      state.surgeryFavorites = [];
    },
    setOrthopedicsItems(state, { page, list, favorites }) {
      state.orthopedicsPage = page;
      state.orthopedicsItems = state.orthopedicsItems.concat(list);
      state.orthopedicsFavorites = favorites;
    },
    resetOrthopedicsItems(state) {
      state.orthopedicsPage = 1;
      state.orthopedicsItems = [];
      state.orthopedicsFavorites = [];
    },
    setOrthodonticsItems(state, { page, list, favorites }) {
      state.orthodonticsPage = page;
      state.orthodonticsItems = state.orthodonticsItems.concat(list);
      state.orthodonticsFavorites = favorites;
    },
    resetOrthodonticsItems(state) {
      state.orthodonticsPage = 1;
      state.orthodonticsItems = [];
      state.orthodonticsFavorites = [];
    },
    setTerapyItems(state, { page, list, favorites }) {
      state.terapyPage = page;
      state.terapyItems = state.terapyItems.concat(list);
      state.terapyFavorites = favorites;
    },
    resetTerapyItems(state) {
      state.terapyPage = 1;
      state.terapyItems = [];
      state.terapyFavorites = [];
    },
    setPhotoBankItems(state, { page, list }) {
      state.photoBankPage = page;
      state.photoBankList = state.photoBankList.concat(list);
    },
    setPhotoBankType(state, { type }) {
      state.photoBankPage = 1;
      state.photoBankList = [];
      state.photoBankType = type;
    },
  },

  // Действия которые запускают изменения состояний
  actions: {
    signin({ commit }, token) {
      localStorage.setItem("token", token);
      commit("signin");
    },
    signout({ commit }) {
      localStorage.removeItem("token");
      commit("signout");
    },
    changeLanguage({ commit }, language) {
      localStorage.setItem("language", language);
      commit("changeLanguage");
    },
    setSurgeryItems({ commit }, data) {
      commit("setSurgeryItems", data);
    },
    resetSurgeryItems({ commit }) {
      commit("resetSurgeryItems");
    },
    setOrthopedicsItems({ commit }, data) {
      commit("setOrthopedicsItems", data);
    },
    resetOrthopedicsItems({ commit }) {
      commit("resetOrthopedicsItems");
    },
    setOrthodonticsItems({ commit }, data) {
      commit("setOrthodonticsItems", data);
    },
    resetOrthodonticsItems({ commit }) {
      commit("resetOrthodonticsItems");
    },
    setTerapyItems({ commit }, data) {
      commit("setTerapyItems", data);
    },
    resetTerapyItems({ commit }) {
      commit("resetTerapyItems");
    },
    setPhotoBankItems({ commit }, data) {
      commit("setPhotoBankItems", data);
    },
    setPhotoBankType({ commit }, data) {
      commit("setPhotoBankType", data);
    },
  },
  // Геттеры - при вызове которых возвращаются состояния
  getters: {
    isLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    currentLang: (state) => {
      let presentLang = { ru: "ru", en: "en", ua: "ru" };
      if (typeof presentLang[state.currentLang] != undefined) {
        return presentLang[state.currentLang];
      } else {
        return "ru";
      }
    },
    getSurgeryPage: (state) => {
      return state.surgeryPage;
    },
    getSurgeryItems: (state) => {
      return state.surgeryItems;
    },
    getSurgeryFavoriteItems: (state) => {
      return state.surgeryFavorites;
    },
    getOrthopedicsPage: (state) => {
      return state.orthopedicsPage;
    },
    getOrthopedicsItems: (state) => {
      return state.orthopedicsItems;
    },
    getOrthopedicsFavoriteItems: (state) => {
      return state.orthopedicsFavorites;
    },
    getOrthodonticsPage: (state) => {
      return state.orthodonticsPage;
    },
    getOrthodonticsItems: (state) => {
      return state.orthodonticsItems;
    },
    getOrthodonticsFavoriteItems: (state) => {
      return state.orthodonticsFavorites;
    },
    getTerapyPage: (state) => {
      return state.terapyPage;
    },
    getTerapyItems: (state) => {
      return state.terapyItems;
    },
    getTerapyFavoriteItems: (state) => {
      return state.terapyFavorites;
    },
    getPhotoBankPage: (state) => {
      return state.photoBankPage;
    },
    getPhotoBankType: (state) => {
      return state.photoBankType;
    },
    getPhotoBankList: (state) => {
      return state.photoBankList;
    },
  },
  strict: debug,
});
