import Vue from "vue";
import VueRouter from "vue-router";

const Landing = () => import("./../components/landing/landing.vue");
const StlViewer = () => import("./../components/stlViewer/stlViewer.vue");
const Visualization = () => import("./../components/visualization/visualization.vue");
const Visualization_categories = () => import("./../components/visualization/visualization_categories.vue");
const Visualization_surgery = () => import("./../components/visualization/visualization_surgery.vue");
const Visualization_orthodontics = () => import("./../components/visualization/visualization_orthodontics.vue");
const Visualization_orthopedics = () => import("./../components/visualization/visualization_orthopedics.vue");
const Visualization_terapy = () => import("./../components/visualization/visualization_terapy.vue");
const ToothStyles = () => import("./../components/toothStyles/toothStyles.vue");
const ToothStylesItem = () => import("./../components/toothStyles/toothStyleItem.vue");
const PhotoBank = () => import("./../components/photoBank/photoBank.vue");
const PhotoDownload = () => import("./../components/photoBank/photoDownload.vue");
const DemoModels = () => import("./../components/demoModels/demoModels.vue");
const Viewer3D = () => import("./../components/viewer3D/viewer3D.vue");
const Viewer3Dconstructor = () => import("./../components/viewer3Dconstructor/viewer3Dconstructor.vue");
const ViewerVR = () => import("./../components/viewerVR/viewerVR.vue");
const Profile = () => import("./../components/profile/profile.vue");
const Library3D = () => import("./../components/library3D/library3D.vue");
const Skull3D = () => import("./../components/skull3D/skull3D.vue");
const OrthodonticSystems = () => import("./../components/orthodonticSystems/orthodonticSystems.vue");
const OrthodonticSystemsItem = () => import("./../components/orthodonticSystems/orthodonticSystemsItem.vue");
const ToothStylesExpression = () => import("./../components/toothStylesExpression/toothStylesExpression.vue");
const ToothStylesExpressionItem = () => import("./../components/toothStylesExpression/toothStyleExpressionItem.vue");
const AttachmentsLibrary = () => import("./../components/attachmentsLibrary/attachmentsLibrary.vue");
const AttachmentsLibraryItem = () => import("./../components/attachmentsLibrary/attachmentsLibraryItem.vue");

//single pages
const Contacts = () => import("./../components/single_pages/contacts.vue");
const PublicOffer = () => import("./../components/single_pages/publicOffer.vue");
const Contract = () => import("./../components/single_pages/contract.vue");

Vue.use(VueRouter);

var router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    // console.log(to, from, savedPosition);
    let position = { x: 0, y: 0 };
    let storedPosition = localStorage.getItem("prevScroll");
    let storedPage = localStorage.getItem("prevScrollPage");

    // Keep scroll position when using browser buttons and catch if has saved scroll position
    if (savedPosition) {
      position = savedPosition;
    } else if (storedPosition && storedPage === to.name) {
      position = { x: 0, y: parseInt(storedPosition) };
    }
    // console.log(position, storedPage, to.name);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 700);
    });
  },
  routes: [
    { path: "/", name: "landing", component: Landing },
    {
      path: "/submit_profile/:id/:token",
      props: { submit_profile: true },
      component: Landing,
    },
    {
      path: "/reset_password/:id/:token",
      props: { reset_password: true },
      component: Landing,
    },
    {
      path: "/signin",
      name: "signin",
      props: { isSignin: true },
      component: Landing,
    },
    {
      path: "/signup",
      name: "signup",
      props: { isSignup: true },
      component: Landing,
    },

    { path: "/stl_viewer", name: "stlViewer", component: StlViewer },
    {
      path: "/viewer_3d/:type/:set",
      name: "viewer3Dremote",
      component: Viewer3D,
    },
    { path: "/viewer_3d", name: "viewer3Dlocal", component: Viewer3D },
    {
      path: "/viewer_3d_constructor",
      name: "viewer3Dconstructor",
      component: Viewer3Dconstructor,
    },
    { path: "/viewer_vr/:set", name: "viewerVRremote", component: ViewerVR },
    //{path: '/viewer_vr', name: 'viewerVRlocal', component: ViewerVR},
    {
      path: "/visualization",
      component: Visualization,
      children: [
        {
          path: "/",
          name: "visualization",
          component: Visualization_categories,
        },
        {
          path: "surgery",
          name: "visualization_surgery",
          component: Visualization_surgery,
        },
        {
          path: "orthodontics",
          name: "visualization_orthodontics",
          component: Visualization_orthodontics,
        },
        {
          path: "orthopedics",
          name: "visualization_orthopedics",
          component: Visualization_orthopedics,
        },
        {
          path: "terapy",
          name: "visualization_terapy",
          component: Visualization_terapy,
        },
      ],
    },
    {
      path: "/library_3d",
      name: "library3d",
      component: Library3D,
    },
    {
      path: "/skull_3d",
      name: "skull3d",
      component: Skull3D,
    },
    {
      path: "/orthodontic_systems",
      name: "orthodonticSystems",
      component: OrthodonticSystems,
    },
    {
      path: "/orthodontic_systems/:styleId",
      name: "orthodonticSystemsItem",
      component: OrthodonticSystemsItem,
    },

    {
      path: "/tooth_styles_expression",
      name: "toothStylesExpression",
      component: ToothStylesExpression,
    },
    {
      path: "/tooth_styles_expression/preview",
      props: { is_previewer: true },
      component: ToothStylesExpression,
    },
    {
      path: "/tooth_styles_expression/:styleId",
      name: "toothStylesExpressionItem",
      component: ToothStylesExpressionItem,
    },
    {
      path: "/attachments_library",
      name: "attachmentsLibrary",
      component: AttachmentsLibrary,
    },
    {
      path: "/attachments_library/:styleId",
      name: "attachmentsLibraryItem",
      component: AttachmentsLibraryItem,
    },

    {
      path: "/tooth_styles/preview",
      props: { is_previewer: true },
      component: ToothStyles,
    },
    {
      path: "/tooth_styles/:styleId",
      name: "toothStylesItem",
      component: ToothStylesItem,
    },
    { path: "/tooth_styles", name: "toothStyles", component: ToothStyles },
    { path: "/photo_bank", name: "photoBank", component: PhotoBank },
    {
      path: "/photo_bank/:photoId",
      name: "photoBankDownload",
      component: PhotoDownload,
    },
    { path: "/demo_models", name: "demoModels", component: DemoModels },
    { path: "/profile", name: "profile", component: Profile },

    { path: "/contacts", name: "contacts", component: Contacts },
    { path: "/public_offer", name: "publicOffer", component: PublicOffer },
    { path: "/contract", name: "contract", component: Contract },
  ],
});

export default router;
