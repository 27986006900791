import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueProgressBar from "vue-progressbar";
import VueAnalytics from "vue-analytics";
import Axios from "axios";
import authMiddleware from "./authMiddleware";
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon.vue";

const progressOptions = {
  color: "#00ab9e",
  failedColor: "#874b4b",
  thickness: "3px",
  transition: {
    speed: "0.3s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, progressOptions);

Vue.use(VueAnalytics, {
  id: "UA-121797711-1",
  checkDuplicatedScript: true,
});

Vue.use(authMiddleware, Axios, router);

Vue.prototype.$http = Axios;

Vue.component("icon", Icon);

// bytes to fixed size
Vue.filter("toFileSize", (size) => {
  let Kb = 1 * 1024;
  let Mb = Kb * 1024;
  let Gb = Mb * 1024;

  if (size < Kb) return Math.round(size) + " byte";
  if (size >= Kb && size < Mb) return Math.round(size / Kb) + " Kb";
  if (size >= Mb && size < Gb) return Math.round(size / Mb) + " Mb";
  if (size >= Gb) return Math.round(size / Gb) + " Gb";
});

Vue.config.productionTip = false;

const root = new Vue({
  router,
  store,
  render: (h) => h(App),
});

document.addEventListener("DOMContentLoaded", () => {
  root.$mount("#app");
});
